<template>
    <Page
        :title="title"
        icon="mdi-settings"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        <file-upload
            v-if="fileType"
            :label="fileType"
            :file-type="fileType"
            :key="fileType"
            @set-files="setFiles"
            multiple
            allow-clipboard
        />

        <toolbar :right-items="[{ preset: 'done', onClick: clickDone, size: 'small' }]" />
    </Page>
</template>
<script>
import FileUpload from '@/components/form/fields/fileUpload/FileUploadField';
import api from '@/api';

export default {
    components: { FileUpload },
    data() {
        return {
            title: 'Too Easy File Upload',
            loadingMessage: 'Saving File.',
            isShowLoading: false,
            errorMessage: '',
            isShowError: false,
            fileType: '',
            query: null,
            fileObjs: null,
            sourceEntityType: '',
            sourceEntityValue: '',
        };
    },
    methods: {
        async clickDone() {
            //api calls fatapi to fetch file and then dump the files to bambam

            const { redirecturl } = this.query;

            if (redirecturl) {
                window.location.href = redirecturl;
            }
        },
        async setFiles(fileObjs) {
            console.log({ fileObjs });
            this.fileObjs = fileObjs;

            const allFileIds = this.fileObjs.map(oneFileObj => oneFileObj.file.id);

            if (this.fileObjs && this.fileObjs.length) {
                await Promise.all(
                    this.fileObjs.map((oneFileObj) =>
                        api.post(this.$store, 'file/uploadfiletote', {
                            file: oneFileObj.file,
                            sourceEntityType: this.sourceEntityType,
                            sourceEntityValue: this.sourceEntityValue,
                            allFileIds,
                        }),
                    ),
                );
            } else {
                console.log('about to hit upload te')
                await api.post(this.$store, 'file/uploadfiletote', {
                    sourceEntityType: this.sourceEntityType,
                    sourceEntityValue: this.sourceEntityValue,
                    allFileIds,
                });
                console.log('returned upload te')
            }

        },
    },

    mounted() {
        this.query = this.$route.query || {};
        console.log({ query: this.query });
        //construct file type from the query http://localhost:8888/auth/admin/config?srcentitytype=dochead&sourceEntityValue=94029&redirecturl=https://www.google.com?srcentityxxxx
        const { srcentitytype: sourceEntityType, srcentityvalue: sourceEntityValue } = this.query;
        if (sourceEntityType && sourceEntityValue) {
            this.sourceEntityType = sourceEntityType;
            this.sourceEntityValue = sourceEntityValue;
            this.fileType = `2Easy ${sourceEntityType} ${sourceEntityValue}`;
        }
    },
};
</script>
